import mapboxgl from '!mapbox-gl';

import { track } from 'context/AnalyticsProvider';
import { getUrl, getUrlByType, removeSpecialCharacters } from 'libs/content';

/**
 * Bind cluster events to the map
 * @param {Object} map - Map object
 * @returns {void}
 */
export function bindClusterEvents(map, source = '') {
	if (!map || !source) return;

	map.on('click', 'clusters', e => {
		const features = map.queryRenderedFeatures(e.point, {
			layers: ['clusters'],
		});

		const clusterId = features[0].properties.cluster_id;

		map.getSource(source).getClusterExpansionZoom(
			clusterId,
			(err, zoom) => {
				if (err) return;

				map.easeTo({
					center: features[0].geometry.coordinates,
					zoom: zoom,
				});
			}
		);
	});
}

/**
 * Bind cursor events to the map
 * @param {Object} map - Map object
 * @returns {void}
 */
export function bindCursorEvents(map) {
	if (!map) return;
	['clusters'].forEach(layer => {
		map.on(
			'mouseenter',
			layer,
			() => (map.getCanvas().style.cursor = 'pointer')
		);
		map.on('mouseleave', layer, () => (map.getCanvas().style.cursor = ''));
	});
}

/**
 * Create a Font Awesome marker
 * @param {Object} options - Marker options
 * @param {string} options.className - Class name of the marker
 * @param {string} options.iconClass - Font Awesome icon class
 * @param {string} options.bgColor - Background color of the marker (blue or green)
 * @param {number} options.size - Size of the marker in pixels
 * @param {boolean} options.iconBg - Whether the icon should have a background
 * @returns {Object} Marker element
 */
export function createFontAwesomeMarker({
	className = 'marker-icon',
	iconClass,
	bgColor = 'blue',
	size = 40,
	iconBg = true,
}) {
	const style = document.createElement('style');
	style.innerHTML = iconBg
		? `
		.${className}.bg-${bgColor}:hover {
			background-color: ${
				bgColor === 'transparent'
					? 'transparent'
					: bgColor === 'green'
					? '#295334'
					: '#094462'
			} !important; // blue800 or green800
			color: #ffffff !important; 
		}`
		: `
		.${className}.hover svg {
			color: ${
				bgColor === 'green' ? '#295334' : '#094462'
			} !important; // blue800 or green800
		}
		.${className} svg {
			transition: all 0.3s ease;
		}
	`;

	document.head.appendChild(style);

	const container = document.createElement('div');
	container.className = `${className} bg-${bgColor}`;
	container.style.cssText = `

        width: ${size}px;
        height: ${size}px;
        display: flex;
        align-items: center;
        justify-content: center;
		cursor: pointer;
		transition: background-color 0.3s ease;
        ${
			iconBg
				? `border-radius: 50%; background-color: ${
						bgColor === 'green' ? '#5BA070' : '#0079AD'
				  }; border: 2px solid #ffffff;`
				: ''
		}
		// blue600 or green600
    `;

	const icon = document.createElement('i');
	icon.className = iconClass;
	icon.style.cssText = `
        font-size: ${iconBg ? size / 2 : size / 1.3}px;
        color: ${iconBg ? '#ffffff' : '#0079AD'};
    `;

	container.appendChild(icon);

	// Add event listeners for hover effect
	container.addEventListener('mouseenter', () => {
		container.classList.add('hover');
	});
	container.addEventListener('mouseleave', () => {
		container.classList.remove('hover');
	});

	return container;
}

/**
 * Hook to create a popup
 * @param {Array} coordinates - Coordinates of the feature
 * @param {string} source - Source of the map
 * @param {string} onClick - Click event for the link inside the popup
 * @param {Object} props - Additional properties
 * @returns {Object} Popup object
 */
export function createPopUp({
	coordinates,
	source,
	onClick,
	markers = [],
	showId = false,
}) {
	if (!coordinates || !source || markers.length === 0) return;

	const list = markers.map(marker => {
		const {
			name,
			intro,
			text,
			category,
			year,
			municipality,
			link,
			linkText,
		} = marker.props;

		const title = showId
			? `<h3>${name} <small>(${marker?.id})</small></h3>`
			: `<h3>${name}</h3>`;
		const specs = [category, municipality, year].filter(Boolean).join(', ');

		let html = `${title}${
			intro || specs ? `<p>${intro || specs}</p>` : ''
		}`;
		if (link && linkText) {
			html += `<div><button id="marker-${marker.id}" title="${linkText}">${linkText}</button></div>`;
		} else if (text) {
			html += `<p>${parseText(text)}</p>`;
		}

		return `<div class="marker">${html}</div>`;
	});

	const popupContent = `<div class="markers markers-${
		markers.length > 1 ? 'multiple' : 'single'
	}">${list.join('')}</div>`;
	const popup = new mapboxgl.Popup({ offset: 25, className: 'map-popup' })
		.setLngLat(coordinates)
		.setHTML(popupContent);

	// Add event listener for the link inside the popup (if any)
	popup.on('open', () => {
		markers.forEach(marker => {
			if (!marker?.props?.link || !marker?.props?.linkText) return;

			const popupLink = document.getElementById(`marker-${marker?.id}`);
			if (!popupLink) return;

			popupLink.addEventListener('click', () => {
				track('Button Clicked', {
					label: marker?.props?.linkText,
					category: window.location.pathname,
					href: marker?.props?.link,
				});
				if (onClick) onClick(marker?.props);
			});
		});
	});

	return popup;
}

/**
 * Fit the map to the bounds of the GeoJSON features
 * @param {Object} map - Map object
 * @param {Array} geoJsonFeatures - Array of GeoJSON features
 * @param {number} zoom - Zoom level
 * @returns {void}
 */
export function fitMapToBounds(map, geoJsonFeatures, zoom = 7) {
	if (!geoJsonFeatures?.length > 0 || !map) return;
	const bounds = new mapboxgl.LngLatBounds();
	if (!bounds) return;
	geoJsonFeatures?.forEach(feature => {
		if (feature?.geometry?.coordinates?.length > 0) {
			bounds.extend(feature?.geometry?.coordinates);
		}
	});
	map.fitBounds(bounds, { padding: 100, maxZoom: zoom });
}

/**
 * Set the active list item and scroll to it
 * @param {number} value - Id of the active list item
 * @param {string} className - Class name of the active list item
 * @param {number} offset - Scroll offset in pixels
 * @returns {void}
 */
export function setActiveListItem(
	value,
	className = 'active-list-item',
	offset = 100
) {
	const activeListItem = document.querySelector(`.${className}`);
	if (activeListItem) {
		activeListItem.classList.remove(className);
	}

	if (!value) return;

	let sectionId = value;

	// If value contains '--', it is a child item, so we need to scroll to the parent
	if (value?.includes('--')) {
		const parentId = value.split('--')[1];
		if (parentId) sectionId = parentId;
	}

	const newActiveItem = document.getElementById(sectionId.toString());

	if (!newActiveItem) return;

	newActiveItem?.parentNode?.classList.add(className);

	// Scroll into view with offset
	const scrollOptions = { behavior: 'smooth', block: 'nearest' };
	newActiveItem.scrollIntoView(scrollOptions);

	// Adjust the scroll position with the offset after a delay
	setTimeout(() => {
		const parent = newActiveItem.parentNode.parentNode;
		if (parent) {
			const parentRect = parent.getBoundingClientRect();
			const itemRect = newActiveItem.getBoundingClientRect();
			const offsetPosition = itemRect.top - parentRect.top - offset;

			parent.scrollBy({ top: offsetPosition, behavior: 'smooth' });
		}
	}, 100); // Adjust the delay as needed
}

/**
 * Clear the active list item
 * @param {string} className - Class name of the active list item
 * @returns {void}
 */
export function clearActiveListItem(className = 'active-list-item') {
	const activeListItem = document.querySelector(`.${className}`);
	if (!activeListItem) return;
	activeListItem.classList.remove(className);
}

/**
 * Format GeoJSON data
 * @param {Object} data - Data to format
 * @returns {Object} - Formatted GeoJSON data
 */
export function formatGeoJson(data) {
	if (!data) return {};

	const id =
		data?.id || (data?.title ? removeSpecialCharacters(data?.title) : null);

	const link = data?.internalLink
		? data?.link?.slug
		: data?.link?.internal?.type
		? getUrlByType(data?.link.slug, data?.link.internal.type)
		: getUrl(data?.link?.slug);

	return {
		type: 'Feature',
		properties: {
			id,
			name: data?.title,
			link,
			internalLink: data?.internalLink,
			icon: data?.category ? getCategoryIcon(data?.category) : null,
			linkText:
				data?.link && data?.linkText
					? data?.linkText
					: data?.link
					? `Les mer om ${data?.title}`
					: null,
			text: data?.text,
			intro: data?.intro,
			markerSize: data?.markerSize,
			year: data?.year,
			category: data?.category,
			municipality: data?.municipality || data?.place,
			target: data?.target,
		},
		geometry: {
			type: 'Point',
			coordinates:
				data?.location?.lon && data?.location?.lat
					? [data?.location?.lon, data?.location?.lat]
					: [],
			location_custom: data?.location_custom,
		},
	};
}

/**
 * Convert an array of objects to GeoJSON features
 * @param {Array} array - Array of objects
 * @returns {Array} - Array of GeoJSON features
 */
export function convertArrayToGeoJson(array) {
	if (!Array.isArray(array) || array.length === 0) {
		return [];
	}

	return array.map(formatGeoJson).filter(Boolean);
}

/**
 * Get the icon for a category
 * @param {string} category - Category of the item
 * @returns {string} - Font Awesome icon class
 */
function getCategoryIcon(category) {
	const icons = {
		Kultur: 'fa-solid fa-masks-theater',
		Idrett: 'fa-solid fa-table-tennis-paddle-ball',
		Arrangement: 'fa-solid fa-calendar-star',
		'Laget Mitt': 'fa-solid fa-sack-dollar',
		Annet: 'fa-solid fa-hands-clapping',
	};

	return icons[category] || 'fa-solid fa-hands-clapping';
}

/**
 * Parse text to HTML markup
 * @param {string|Array} text - Text to parse
 * @returns {string} - HTML markup
 */
function parseText(text) {
	if (!text) return '';

	try {
		text = JSON.parse(text);
	} catch (e) {
		// If text is not a JSON string, it will remain as is
	}

	if (typeof text === 'string') {
		return `<p>${text}</p>`;
	}

	if (Array.isArray(text)) {
		return `<ul>${text.map(item => `<li>${item}</li>`).join('')}</ul>`;
	}

	return '';
}

/**
 * Merge titles with separator, make sure to remove duplicates
 * @param {Array} titles - Array of titles
 * @returns {string} - Merged title
 */
export function mergeTitles(titles = []) {
	// Remove duplicates by converting to a Set and back to an array
	const uniqueTitles = [...new Set(titles)];

	if (uniqueTitles.length === 0) return '';
	if (uniqueTitles.length === 1) return uniqueTitles[0];
	if (uniqueTitles.length === 2) return uniqueTitles.join(' og ');
	const lastTitle = uniqueTitles.pop();
	return `${uniqueTitles.join(', ')} og ${lastTitle}`;
}
