import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { faExpand } from '@fortawesome/pro-regular-svg-icons/faExpand';
import { faCompress } from '@fortawesome/pro-regular-svg-icons/faCompress';

import useFullScreen from 'context/useFullScreen';
import useWindow from 'context/useWindow';
import Button from 'components/forms/Button';

const OuterWrap = styled.div`
	background-color: white;
	width: 100%;
	position: relative;
	&.fullscreen-active {
		overflow-y: scroll;
		padding: 0;
		.inner-wrap {
			min-height: 100vh;
		}
	}
`;

const Wrap = styled.div`
	${p =>
		p.theme.media.large(css`
			display: grid;
			grid-template-rows: 1fr;
			grid-column-gap: 0px;
			grid-row-gap: 0px;
			grid-template-columns: repeat(2, 1fr);
			min-height: ${p =>
				p.minheight
					? `calc(${p.minheight} - 100px)`
					: 'calc(100vh - 100px)'};
		`)}

	.container-cell {
		overflow: hidden;
		position: relative;
	}
`;

const RightColumn = styled.div`
	padding: ${p =>
		`${p.theme.spacing.desktop.xsmall} ${p.theme.spacing.desktop.xsmall} 0`};
	${p =>
		p.theme.media.large(css`
			padding: ${p =>
				`${p.theme.spacing.desktop.small} ${p.theme.spacing.desktop.small} 0`};
		`)}

	> div {
		height: 100%;
	}

	.list {
		position: relative;
		min-height: 400px;
		&::after {
			content: '';
			position: absolute;
			right: 0px;
			bottom: 0px;
			height: 50px;
			width: 100%;
			z-index: 2;
			pointer-events: none;
			border-radius: ${p =>
				`0 ${p.theme.utils.borderRadius} ${p.theme.utils.borderRadius} 0`};
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				#ffffff 100%
			);
		}
		&.sponsorship-list .map-list {
			height: calc(100vh - 300px);
			${p =>
				p.theme.media.large(css`
					height: calc(100vh - 300px);
				`)}
		}
		&.elektro-departments-list .map-list {
			height: calc(100vh - 300px);
			${p =>
				p.theme.media.large(css`
					height: calc(100vh - 300px);
				`)}
		}
	}
`;

const StickyFullscreenToggle = styled.div`
	position: absolute;
	bottom: 40px;
	left: 15px;
	z-index: 4;
`;

export default function Container({
	map,
	rightContent,
	fullscreen = false,
	...props
}) {
	const [showFullScreen, setShowFullScreen] = useState(false);

	const { windowWidth } = useWindow();
	useFullScreen('fullscreen-container', showFullScreen, setShowFullScreen);

	return (
		<OuterWrap
			id="fullscreen-container"
			data-cy="fullscreen-container"
			className={`${
				!!(fullscreen && showFullScreen) && 'fullscreen-active'
			}`}>
			<Wrap {...props} className="inner-wrap">
				<div className="container-cell">
					{map}
					{fullscreen && (
						<StickyFullscreenToggle>
							<Button
								id="toggle-fullscreen"
								data-cy="toggle-fullscreen"
								outlined="true"
								icon={
									(showFullScreen && faCompress) || faExpand
								}
								small={windowWidth <= 766}
								leadingIcon={true}
								onClick={() =>
									setShowFullScreen(!showFullScreen)
								}>
								{(!showFullScreen && 'Fullskjerm') ||
									'Lukk fullskjerm'}
							</Button>
						</StickyFullscreenToggle>
					)}
				</div>
				<RightColumn className="container-cell">
					{rightContent}
				</RightColumn>
			</Wrap>
		</OuterWrap>
	);
}

const FilterWrap = styled.div`
	margin-bottom: 0;
	&:last-of-type {
		margin-bottom: 0;
	}
	> div {
		margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
		${p =>
			p.theme.media.XSmallOnly(css`
				margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
			`)}
	}
`;

const FiltersWrapper = styled.div`
	margin: 0;
	${p =>
		p.theme.media.XSmallOnly(css`
			margin-bottom: ${p => p.theme.spacing.desktop.xxsmall};
		`)}

	${p =>
		p.$perrow > 1 &&
		css`
			display: flex;
			flex-wrap: wrap;
			margin: 0 -10px 0;
			${FilterWrap} {
				flex: 1 1 calc(100% / ${p => p.$perrow} - 20px);
				padding: 0 ${p => p.theme.spacing.desktop.xxsmall};
				margin-bottom: 0;
				${p =>
					p.theme.media.mediumDown(css`
						flex: 1 1 calc(100% / 2 - 20px);
					`)}
				${p =>
					p.theme.media.XSmallOnly(css`
						flex: 1 1 calc(100% - 20px);
					`)}
			}
		`}

	${p =>
		p.$perrow > 3 &&
		css`
			${FilterWrap} {
				${p =>
					p.theme.media.tabletOnly(css`
						flex: 1 1 calc(100% / 2 - 20px);
					`)}
			}
		`}
`;

/**
 * Filters component for map
 * @param {Array} items - Array of filter items
 * @param {number} perRow - Number of items per row (default 3)
 * @returns {JSX.Element}
 */
export function Filters({ items, perRow = 3, ...props }) {
	if (!items) return null;
	return (
		<FiltersWrapper {...props} $perrow={perRow}>
			{items?.map((item, i) => (
				<FilterWrap key={i} id={item?.id}>
					{item}
				</FilterWrap>
			))}
		</FiltersWrapper>
	);
}
